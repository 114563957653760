<template>
    <b-overlay :show="isLoadingNewsletter || isUpdatingNewsletter">
        <b-card >
            <div class="head-btn">
                <b-button
                    :to="{ name: 'owners-newsletter'}"               
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="warning"                                  
                >
                    <feather-icon icon="ArrowLeftIcon" style="color: white" />
                </b-button>
            </div> 
            <div v-if="newsletterData">
                <ValidationObserver
                    ref="createWebProp"
                    v-slot="{ invalid }"
                >
                    <b-form @submit.prevent="update">
                    <b-row>
                        <b-col md="3">
                            <ValidationProvider name="lenguaje" rules="required">
                            <b-form-group label="Lenguaje*" slot-scope="{ valid, errors }">
                                <b-form-select
                                :state="errors[0] ? false : valid ? true : newsletterData.language != '' ? true : false"
                                v-model="newsletterData.language"
                                >
                                <option value="" disabled>Seleccione Lenguaje</option>
                                <option v-for="lang in languages" :key="lang.id" :value="lang.id">
                                    {{ lang.name }}
                                </option>
                                </b-form-select>
                                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col md="3">
                        <ValidationProvider rules="required" name="nombre">
                            <b-form-group label="Nombre" slot-scope="{ valid, errors }">
                            <b-form-input
                                class="form-control"
                                type="text"
                                v-model="newsletterData.name"
                                :state="errors[0] ? false : valid ? true : null"
                            >
                            </b-form-input>
                            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </ValidationProvider>
                        </b-col>
                        <b-col md="3">
                        <ValidationProvider rules="required" name="descripcion">
                            <b-form-group label="Descripción" slot-scope="{ valid, errors }">
                            <b-form-input
                                class="form-control"
                                type="text"
                                v-model="newsletterData.description"
                                :state="errors[0] ? false : valid ? true : null"
                            >
                            </b-form-input>
                            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </ValidationProvider>
                        </b-col>
                            <b-col md="3">
                        <ValidationProvider rules="required" name="link">
                            <b-form-group label="Link" slot-scope="{ valid, errors }">
                            <b-form-input
                                class="form-control"
                                type="text"
                                v-model="newsletterData.link"
                                :state="errors[0] ? false : valid ? true : null"
                            >
                            </b-form-input>
                            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </ValidationProvider>
                        </b-col>

                        <b-col md="6">
                        <div class="float-left pt-2 mt-1">
                            <b-form-checkbox
                            v-model="newsletterData.status"
                            :value="true"
                            class="custom-control-success"
                            >
                            Activado
                            </b-form-checkbox>
                        </div>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                        <div class="float-right">
                            <b-button
                            type="submit"
                            class="btn-block"
                            variant="primary"
                            :disabled="invalid"
                            >
                             Actualizar
                            </b-button>
                        </div>
                        </b-col>
                    </b-row>
                    </b-form>
                </ValidationObserver>
            </div>
            <b-alert show variant="danger" class="p-1 text-center" v-else>
                No se encontró información                                                      
            </b-alert> 
        </b-card>
       
    </b-overlay>       
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import Ripple from 'vue-ripple-directive'
import { showAlertMessage } from '@/helpers/helpers'

export default {
    directives: {Ripple, },

    components: {
       
    },       
    computed: {
        ...mapState('auth', ['user']),  
        ...mapState("start", ["languages"]),    
    },
    async created() {
        await this.init()
    },
    data(){
        return {
            newsletterData: null,
            isLoadingNewsletter: false,
            isUpdatingNewsletter: false,
        }
    },
    methods: {      
        ...mapActions('fivesClubCatalogs', ['getInitialContentForNewsletter','fetchNewsLetterById','updateNewsLetters']),
        ...mapMutations('start',['setLanguages']),

        async init(){
            const idNewsletter = this.$route.params.id
            this.isLoadingNewsletter = true
            this.newsletterData = null

            if( this.isANumber(idNewsletter) ){
                const { languages } = await this.getInitialContentForNewsletter()
        
                if( !this.languages.length ){
                    this.setLanguages(languages)
                }

                this.newsletterData = await this.fetchNewsLetterById({idNewsletter})
            }

            this.isLoadingNewsletter = false
        },
        isANumber(string){
            return string ? !isNaN( string ) && string > 0 : false
        },
        async update() {
            this.isUpdatingNewsletter = true;

            const payload = {
                ...this.newsletterData,
                idUser: this.user.idUser,
            }
           
            const { status, message } = await this.updateNewsLetters(payload); // updateWebProp in backend

            if (status) {
                showAlertMessage('Ok: proceso finalizado', 'InfoIcon', message, 'success', 4000, 'bottom-right' )
			    this.$router.push({ name: 'owners-newsletter' })					
            }

            this.isUpdatingNewsletter = false;
        },
    },
};
</script>

<style scoped>
.head-btn{
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
}
</style>
